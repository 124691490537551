.fade-enter {
    opacity: 0.5;
    transform: translateY(.5rem);
    position: relative;
    z-index: 1;
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

.fade-exit {
    display: none;
}