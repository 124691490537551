@import "../../style/theme";
.more-bill-wrap {
    padding: 0 .42rem;
}

.bill-item {
    display: flex;
    flex-direction: column;
    padding-bottom: .2rem;
    &:not(:last-child) {
        border-bottom: 1px solid #ddd;
    }
    .bill-item-title {
        display: flex;
        justify-content: space-between;
        padding-top: .3rem;
        position: relative;

        .title-left {
            font-size: .32rem;
            line-height: .45rem;
            color: #333;

            .title-tips {
                color: #ff0000;
                font-size: .26rem;
                line-height: .37rem;
                border: 1px solid #ff0000;
                display: inline-block;
                margin-left: .23rem;
            }
        }

        .payment-btn {
            width: 1rem;
            height: .48rem;
            font-size: .26rem;
            text-align: center;
            color: #fff;
            line-height: .48rem;
            border-radius: $border-radius;
            background: btn-bg-theme-color();
        }
        .paid{
            position: absolute;
            font-size: 1rem;
            right: 0;
            top: .5rem;
            transform: rotate(25deg);
            color: #ddd;
            .iconfont {
                font-size: 1rem;
            }
        }
    }
}

.title-c8 {
    color: #888;
    font-size: .28rem;
    line-height: .55rem;
}

.overdue {
    color: #ff0000;
    font-size: .28rem;
}
.noOverdue {
    color: theme-color();
    font-size: .28rem;
}
.payment-date {
    color: #333;
    font-size: .28rem;
}