@import "../../style/theme";
.spin-container {
  position: relative;

  .loading-block {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #fff, $alpha: 0.88);


    .spin {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-image: conic-gradient(#fff 0%,green 80%,#fff 80% 100%);
      position: relative;
      animation: rotateZ 2s linear infinite;
      &::after {
        content: '';
        position: absolute;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }
}

@keyframes rotateZ {
  0%{
    transform: rotateZ(0deg)
  }
  100% {
    transform: rotateZ(360deg);
  }
}


