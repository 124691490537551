@import "../../style/theme";

.add-bank {
    height: 100%;
    background-color: #f8f8f8;

    .form {
        padding: .2rem 0;

        .slect-bank {
            display: flex;
            justify-content: space-between;
            padding-right: .5rem;
        }

        .selcet-treaty {
            line-height: 1;
            color: theme-color();
            padding: .34rem .4rem 0;
        }

        .form-btn {
            width: 5.9rem;
            margin-top: .5rem;
        }
    }

    .m-r {
        margin-right: .15rem;
    }
}