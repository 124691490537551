@import "../../style/theme";

.add-bank {
    height: 100%;
    background-color: #f8f8f8;

    .exp-txt {
        line-height: .8rem;
        font-size: .28rem;
        padding: 0 .4rem;

        span {
            color: theme-color();
        }
    }

    .form {
        padding: 0 0 .2rem;

        .form-btn {
            width: 5.9rem;
            margin-top: .5rem;
        }
    }
}