@import "../../style/theme";

.bill-item-wrap {
  .bill-item-radio-wrap {
      position: relative;
  }

  .bill-box {
      background-color: #fff;
      border-radius: $border-radius;
      padding: .42rem;
      margin-top: .3rem;
      .header {
          display: flex;

          .month { font-size: .3rem; }
      }
  }
  
  .bill-item {
      display: flex;
      flex-direction: column;
      padding-bottom: .2rem;

      .bill-item-title {
          display: flex;
          justify-content: space-between;
          padding-top: .3rem;

          .title-left {
              font-size: .32rem;
              line-height: .45rem;
              color: #333;

              .title-tips {
                  color: #ff0000;
                  font-size: .26rem;
                  line-height: .37rem;
                  border: 1px solid #ff0000;
                  display: inline-block;
                  margin-left: .23rem;
                  padding: 0 .06rem;
              }
          }

          .payment-btn {
              width: 1rem;
              height: .48rem;
              font-size: .26rem;
              text-align: center;
              color: #fff;
              line-height: .48rem;
              border-radius: $border-radius;
              background: btn-bg-theme-color();
          }
      }
  }
}

.radio {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 40px;
  border: 2px solid #67c8d8;
  border-radius: 50%;
  left: -36px;
  display: flex;
  justify-content: center;
  align-items: center;

  .checked {
      display: inline-block;
      border-radius: 50%;
      background-color: #67c8d8;
      width: 50%;
      height: 50%;
 }
}


// 批量支付
.multi-pay-box {
  position: fixed;
  bottom: .98rem;
  left: 0;
  width: 100%;
  z-index: 1;
  font-size: .28rem;
  background-color: #fff;
  box-sizing: border-box;

  color: #333;
  .title {
      margin-right: .1rem;
  }
  .list {
      flex: 1;
      padding: .2rem;
      .scroll-wrap {
          max-height: 2.3rem;
          overflow-y: auto;
      }
      .item {
          display: flex;
       
          .month {
              flex: 1;
          }
      }
  }
  .footer {
      display: flex;
      align-items: center;
      height: 1rem;
      border-top: 1px solid #ddd;


      .left {
          flex: 1;
          padding-left: .2rem;
          font-size: .3rem;
      }

      .pay-btn {
          width: 2rem;
          height: 100%;
          font-size: .3rem;
          background-image: linear-gradient(45deg, #6bd1e2, #56a9b7);
          display: block;
          padding: .1rem 0;
          color: #fff;
      }
  }
}