@import "../../style/theme";

.identity {
    padding: .6rem .6rem 0;
    color: #888;
    font-size: .26rem;

    .title {
        color: #333;
        font-size: .44rem;
        line-height: .62rem;
        text-align: center;
        font-weight: normal;
    }

    .tips {
        line-height: .37rem;
        text-align: center;
        margin-bottom: .6rem;
    }

    .btns {
        display: flex;
        padding-bottom: .7rem;
        border-bottom: 1px solid #ddd;

        .upload-wrap {
            flex: 1;
            box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.06);
            margin-right: .3rem;
            padding-bottom: .2rem;
        }

        .upload-tips {
            font-size: .28rem;
            text-align: center;
            margin-top: .3rem;
        }

        .img-wrap {
            margin: .4rem .33rem 0;
            height: 1.44rem;
            position: relative;
            box-sizing: border-box;
            text-align: center;

            img {
                width: 100%;
                height: 100%;
            }

            input {
                width: 100%;
                opacity: 0;
                position: absolute;
            }

        }
    }

    .notice {
        line-height: .45rem;
        color: #333;
        margin: .25rem 0 .28rem;
        font-size: .32rem;
    }

    .notice-item {
        display: flex;

        >div {
            flex: 1;
            margin-left: .23rem;

            .top-icon {
                height: .98rem;
                margin-bottom: .1rem;
                background-color: #f7f7f9;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .bot-desc {
                text-align: center;
                letter-spacing: .008rem;
                line-height: .37rem;
            }
        }
    }
}

.succ {
    color: theme-color();
}

.fail {
    color: #af3737;
    vertical-align: middle;
}

.container {
    >div {
        display: flex;

        .left-title {
            width: 1.15rem;
            color: #888;
            font-size: .28rem;
            line-height: .8rem;
            text-align: justify;
        }

        .right-content {
            flex: 1;
            color: #333;
            font-size: .32rem;
            line-height: .8rem;
            padding-left: .5rem;
        }

        .name-input {
            font-size: .32rem;
            height: 100%;
            background: #fef9f9;
            padding: 0.1rem 0.25rem;
        }

        .remarks {
            color: theme-color();
            font-size: .28rem;
            line-height: .4rem;
            padding-left: 1.66rem;
        }
    }

}