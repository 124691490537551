@import "../../style/theme";
.merchant-list {
  .merchant-item {
    height: .8rem /* 45/100 */;
    line-height: .8rem /* 45/100 */;
    border-bottom: 1px solid #d1d1d1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
}

.faceCheckView,  .HouseKeeperSignView {
  text-align: center;
  padding-top: .4rem;

  .h1 {
    font-size: .4rem;
    color: #333;
  }

  .h2 {
    font-size: .3rem;
  }

  .form-btn {
    width: 50%;
  }
  .form-btn.sub {
    margin-top: .2rem;
    color: theme-color();
    background-image: unset;
  }
}

 

// 人脸认证结果页
.face-recognition {
  text-align: center;
  padding: 1.5rem 0;

  .processing {

      .icon {
          font-size: 3rem;
          color: theme-color();
      }

      .exp {
          font-size: .5rem;
          color: #000;
          margin: 0.5rem 0 0;
      }
  }

  .fail {
      .icon {
          font-size: 3rem;
          color: #ccc;
      }

      .exp {
          font-size: .5rem;
          color: #000;
          margin: 0.5rem 0 0;
          padding: 0 0.8rem;

          .exp2 {
              font-size: .3rem;
              color: #888;
              padding: .2rem 0;

              .num {
                  color: theme-color();
              }
          }
      }

  }
}