.attach-list {
    display: flex;
    flex-wrap: wrap;
    font-size: .3rem;
    padding: .3rem;
    box-sizing: border-box;

    .attach-item {
        margin-right: .26rem;
        margin-bottom: .26rem;
        .attach-item-img {
            border: 1px solid #eee;
        }
        .attach-item-name{
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            margin-top: .1rem;
        }
    }
}