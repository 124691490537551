.form-label {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: .5rem;

    input::-webkit-input-placeholder {
        color: #ccc;
    }

    .form-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        width: .75rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form-input {
        flex: 1;
        height: .88rem;
        // line-height: .88rem;
        border: 1px solid #ccc;
        border-radius: $border-radius;
        font-size: .32rem;
        padding: 0 .1rem 0 .75rem;
        color: #333;
        background-color: #fff;
    }

    .form-getYzm {
        position: absolute;
        right: 0;
        width: 2.5rem;
        height: .36rem;
        text-align: center;
        font-size: .32rem;
        color: theme-color();
        line-height: 1;
        border-left: 1px solid #ccc;
    }

    .form-custom {
        font-size: .28rem;
        color: #888;

        .treaty {
            color: theme-color();
        }
    }
}

.form-label2 {
    margin-bottom: 0;

    .form-icon {
        width: 2rem;
        box-sizing: border-box;
        padding-left: .4rem;
        justify-content: flex-start;
        font-size: .32rem;
        color: #888;
    }

    .form-input {
        padding-left: 2rem;
        border: none;
        border-radius: 0;
    }
}

.form-btn {
    display: block;
    margin: 1rem auto 0;
    border-radius: $border-radius;
    width: 100%;
    height: .88rem;
    font-size: .36rem;
    color: #fff;
    background-image: btn-bg-theme-color();
}

#root {
    .form-label {

        input,
        textarea {
            &:focus {
                outline: none;
                border: 1px solid theme-color();
                box-shadow: 1px 1px 10px theme-color(.2);
            }
        }
    }

    .form-label2 {

        input,
        textarea {
            &:focus {
                outline: none;
                border: none;
                border-bottom: none;
                box-shadow: none;
            }
        }
    }

    .form-btn {
        &:active {
            background-image: linear-gradient(45deg, #82cdc3, #5a9d94);
        }

        &.disabled {
            background-image: linear-gradient(45deg, #ccc, #ccc);
            box-shadow: none;
        }
    }
}