@import "../../style/theme";

.bind-result {
    height: 100%;
    background: #F8F8F8;
    text-align: center;
    font-size: .44rem;
    color: #363A43;

    .succ {
        padding: 1.8rem 0 .6rem;

        i {
            color: theme-color();
            font-size: 2rem;
        }
    }

    .fail {
        padding: 1.8rem 0 .6rem;

        i {
            color: #ddd;
            font-size: 2rem;
        }
    }

    .count-down {
        font-size: .32rem;
        color: #888;
        line-height: 1;
        padding-top: .2rem;

        span {
            color: theme-color();
        }
    }

    .count-down2 {
        span {
            color: #f00;
        }
    }

    .form-btn {
        width: 5.9rem;
        margin-top: 1rem;
    }
}