@import "../../style/theme";

.payment{
    
    .amount {
        font-size: .28rem;
        background-color: #fff;
        padding: .4rem;
        box-sizing: border-box;
        color: #888;
        .item {
            display: flex;
            justify-content: space-between;
            padding: .03rem 0;
        
            &.sum {
                border-top: 1px solid #ddd;
                padding: .2rem 0;
                margin-top: .2rem;
                font-weight: bold;
            }

            &.discount {
                color: red;
                margin-bottom: .1rem;
                .money {
                    color: red;
                }
            }
            .money {
                color: theme-color();
            }
        }
        
    }

    .payway {
        background-color: #f8f8f8;
        height: .8rem;
        line-height: .8rem;
        padding-left: .4rem;
        color: #888;
        font-size: .28rem;
    }

    .payList {
        margin: 0 .4rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        &:not(:last-child) {
            border-bottom: 1px solid #ddd;
        }
        .name-wrap {
            display: flex;
            
            i {
                line-height: 1.44rem;
                font-size: .45rem;
            }
        }
        .tips {
            font-size: .3rem;
            padding-left: 0.8rem;
            color: #64c6d6;
            margin-bottom: .1rem;
        }
        .payname {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: .32rem;
            padding-left: .33rem;
            justify-content: center;

            .first {
                color: #333;
            }
            .second {
                color: #888;
            }
        }
        
        .circle-wrap {
            display: flex;
            align-items: center;

            .circle {
                width: .32rem;
                height: .32rem;
                border-radius: 50%;
                border: 0.03rem solid #ddd;
                box-shadow: 0.04rem 0.04rem 0.1rem rgba(0,0,0,.08);
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .active {
            .circle {
                border-color:theme-color();
                &::after{
                    content: "";
                    width: .2rem;
                    height: .2rem;
                    background-color: theme-color();
                    border-radius: 50%;
                    
                }
            }
        }
        
    }

    .addCard {
        color: theme-color();
        font-size: .32rem;
        line-height: .45rem;
        padding-left: .4rem;
        margin-top: .3rem;
    }

    .form-btn {
        width: 5.9rem;
        margin-top: .92rem;
    }
}