@import "../../style/theme";

.tips-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    animation-duration: .3s;
    display: flex;
    align-items: center;
    justify-content: center;

    .tips-modle {
        position: relative;
        width: 6.3rem;
        padding-bottom: 1rem;
        background-color: #fff;
        border-radius: $border-radius;
        color: #888;
        font-size: 16px;

        .title {
            text-align: center;
            line-height: 1rem;
            height: 1rem;
            border-bottom: 1px solid #DCE1EB;
            font-size: .36rem;
            color: #333;
        }

        .content {
            max-height: 6.5rem;
            overflow-y: auto;
            font-size: .32rem;
            margin: .2rem .3rem .4rem;
        }

        .btn-list {
            border-top: 1px solid #DCE1EB;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1rem;
            display: flex;

            .btn-item {
                font-size: .36rem;
                flex: 1;
                background-color: transparent;
                border-right: 1px solid #DCE1EB;
                color: inherit;

                &:last-child {
                    border-right: none;
                }

                &.confirm {
                    color: theme-color();
                }

                &:active {
                    background-color: rgba(255, 251, 251, .7);
                }
            }
        }
    }
}