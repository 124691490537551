@import "../../style/theme";

.tabbar-wrap-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;

    .page {
        flex: 1;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .tabbar {
        flex: 0 0 1rem;
        background-color: #fff;
        display: flex;
        border-top: 1px solid #ddd;
        box-sizing: border-box;
        a {
            flex: 1;
            height: 100%;
            font-size: .2rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;

            i {
                font-size: .5rem;
            }

            &.active {
                color: theme-color();
            }
        }
    }
}