@import "./theme";

html,
body,
#root,
#root>div {
    height: 100%;
}

html {
    font-size: .14rem;
    color: #ccc;
    font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

img {
    font-size: .2rem;
    text-align: center;
}

.fl {
    float: left
}

.fr {
    float: right
}

.ovfl {
    overflow: hidden
}

.clear {
    clear: both
}

.clearfix {
    zoom: 1
}

.clearfix:after {
    content: "";
    display: block;
    clear: both
}

.txtCnr {
    text-align: center
}

.tran3,
a {
    transition: .3s
}

.txtH {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.txtMh {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3
}

.app-radio {
    width: .3rem;
    height: .3rem;
    border-radius: 50%;
    border: .045rem solid theme-color(0.5);
    position: relative;

    &.checked {
        border-color: theme-color();
        &::after {
            content: '';
            position: absolute;
            left: .05rem;
            top: .05rem;
            width: .2rem;
            height: .2rem;
            border-radius: 50%;
            background-color: theme-color();
        }
    }
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: .3rem;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'iconfont';
    /* project id 1225583 */
    src: url('//at.alicdn.com/t/font_1225583_cr8i8058ezs.eot');
    src: url('//at.alicdn.com/t/font_1225583_cr8i8058ezs.eot?#iefix') format('embedded-opentype'),
        url('//at.alicdn.com/t/font_1225583_cr8i8058ezs.woff2') format('woff2'),
        url('//at.alicdn.com/t/font_1225583_cr8i8058ezs.woff') format('woff'),
        url('//at.alicdn.com/t/font_1225583_cr8i8058ezs.ttf') format('truetype'),
        url('//at.alicdn.com/t/font_1225583_cr8i8058ezs.svg#iconfont') format('svg');
}