.animated {
    animation-duration: .3s;
    animation-fill-mode: both;
}

@keyframes slideInUp {
    from {
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, -10%, 0);
    }
}

.slideInUp {
    animation-name: slideInUp;
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}