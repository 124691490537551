@import "../../style/theme";

.bank {
    padding: .3rem .4rem;

    .bank-list {
        .bank-item {
            background-image: btn-bg-theme-color();
            border-radius: $border-radius;
            position: relative;
            color: #fff;
            padding: .4rem;
            margin-bottom: .3rem;
            display: flex;
            flex-direction: column;

            .name-message {
                height: .44rem;
                display: flex;
                align-items: center;

                .iconfont {
                    border-radius: 50%;
                    background-color: #fff;
                    width: .44rem;
                    height: .44rem;
                    margin-right: .2rem;
                    text-align: center;
                    font-size: .4rem;
                    color : #f00;
                }

                .name {
                    font-size: .36rem;
                }
            }

            .bank-no {
                margin-top: .2rem;
                padding-left: .64rem;
                font-size: .4rem;
                letter-spacing: .05rem;
            }

            .untying {
                position: absolute;
                right: .2rem;
                top: .2rem;
                color: theme-color();
                font-size: .24rem;
                background-color: #fff;
                border-radius: $border-radius;
                width: 1rem;
                height: .48rem;

                &:active {
                    background-color: rgba(255, 255, 255, .7);
                }
            }
        }
    }

    .add-bank-box {
        padding: 0 .4rem;

        .add-bank {
            display: block;
            width: 100%;
            height: .88rem;
            font-size: .32rem;
            background-color: #fff;
            border-radius: $border-radius;
            border: 1px dashed theme-color();
            color: theme-color();
            letter-spacing: .03rem;

            &:active {
                background-color: rgba(255, 251, 251, .7);
            }
        }
    }
}