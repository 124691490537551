@import "../../style/theme";

.add-bank-select {
    height: 100%;
    background-color: #f8f8f8;

    .bank-list {
        padding-top: .2rem;

        .list-item {
            background-color: #fff;
            border-bottom: 1px solid #ddd;
            font-size: .32rem;
            height: .88rem;
            padding: 0 .4rem;
            color: #000;
            display: flex;
            align-items: center;
        }

        .icon {
            margin-right: .2rem;
            width: .5rem;
            height: .4rem;
            text-align: center;

            .iconfont {
                font-size: .4rem;
            }
        }
    }
}