@import "../../style/theme";

.my {
    .head {
        display: flex;
        align-items: center;
        padding: .5rem .46rem;
        background-image: btn-bg-theme-color();
        color: #fff;

        .portrait {
            width: 1.36rem;
            height: 1.36rem;
            border: 1px solid #fff;
            border-radius: 50%;
        }

        .message {
            margin-left: .4rem;
            height: 1.36rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .msg-txt-box {
                display: flex;
                align-items: center;
                height: .4rem;
                margin-bottom: .1rem;
            }

            .txt1 {
                font-size: .36rem;
            }

            .txt2 {
                font-size: .22rem;
                line-height: 1;
            }

            .txt3 {
                font-size: .26rem;
                line-height: 1;
            }

            .tag {
                padding: 0 .15rem;
                height: 100%;
                background: rgba(255, 255, 255, 0.1);
                border: 1px solid #fff;
                border-radius: .3rem;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                margin-left: .2rem;

                .icon {
                    position: relative;
                    top: .01rem;
                    font-size: .25rem;
                    margin-right: .07rem;
                }

                .txt2-c {
                    position: relative;
                    top: -.01rem;
                }
            }
        }
    }

    .menu-list {
        background-color: #fff;
        margin-top: .2rem;

        .menu-item {
            height: .88rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 .58rem 0 .4rem;

            .left-item {
                display: flex;
                align-items: center;
                font-size: .32rem;
                color: #000;
                line-height: 1;

                .iconfont {
                    font-size: .36rem;
                }

                .title {
                    margin-left: .35rem;
                }
            }

            .right-item {
                font-size: .24rem;
            }
        }
    }

    .form-btn {
        width: 5.9rem;
    }
}