.modal-text-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;

  .modal-text-content {
    background-color: #fff;
    color: #333;
    font-size: .28rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px 12px 0 0;
    width: 80vw;

    .title {
      font-size: .36rem;
      border-bottom: 1px solid #eee;
      text-align: center;
      padding: .1rem;
      margin-bottom: 0;
    }

    .body {
      padding: .3rem .4rem;
      font-size: .28rem;
      color: #4d4f4d;
      line-height: .5rem;
    }

    .footer {
      display: flex;
      border-top: 1px solid #eee;
      span {
        flex: 1;
        text-align: center;
        padding: .2rem 0;
      }
      .right {
        border-left: 1px solid #eee;
        color: #56a9b7;
      }
    }
  }
}