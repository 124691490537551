@import "../../style/theme";

.agreementWrap{
    margin: 0 .8rem;
    padding: .6rem 0;
    h3 {
        font-size: .4rem;
        text-align: center;
        color: theme-color();
    }
    p {
        font-size: .26rem;
        color: #888;
        line-height: .4rem;
        margin-top: 0.2rem;
        text-align: justify;
    }
    .addBold {
        color: #666;
        font-weight: 600;
    }
    .addBoldColor {
        color: theme-color()
    }
}