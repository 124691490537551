@import "../../style/theme";

.hint-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    animation-duration: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    .hint-modle {
        position: relative;
        display: flex;
        width: 6.3rem;
        height: 4.67rem;
        background-color: #fff;
        border-radius: $border-radius;
        color: #fefefe;
        font-size: .26rem;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .content {
            margin-top: .5rem;
            color: #888;
            font-size: .32rem;
        }
    }
}  