.modal-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 100;

    .body {
        background-color: #fff;
        color: #333;
        font-size: .24rem;
        position: absolute;
        bottom: 0;
        width: 100%;
        border-radius: 12px 12px 0 0;

        .title {
            font-size: .3rem;
            border-bottom: 1px solid #eee;
            padding: .2rem;
            padding-left: .3rem;
        }

        .scroll-block {
            max-height: 10rem;
            min-height: 8rem;
            overflow-y: auto;
            padding: .3rem;
            box-sizing: border-box;
        }

        .footer {
            display: flex;
            border-top: 1px solid #eee;
            span {
                flex: 1;
                text-align: center;
                padding: .2rem 0;
            }
            .right {
                border-left: 1px solid #eee;
                color: #56a9b7;
            }
        }
    }
}