// 主题色
@function theme-color($a:1) {
    @return rgba(86, 169, 183, $a);
}

// 按钮及多个场景的渐变
@function btn-bg-theme-color() {
    @return linear-gradient(45deg, #6bd1e2, theme-color());
}

// 10px的圆角
$border-radius: .1rem;