@import "../../style/theme";

.login-loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        font-size: 1rem;
        animation: loading 1.5s linear infinite;
        color: #000;
    }
}

@keyframes loading {
    form {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.login {
    position: fixed;
    width: 100%;
    height: 100%;
    background: url(../../image/login_bg.jpg) center bottom/100% auto no-repeat fixed;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .box-wrap {
        margin: 0 .8rem;
        padding: .6rem 0;
    }

    .title {
        font-size: .6rem;
        color: #333;
        letter-spacing: 1px;
        line-height: 84px;
        margin-bottom: .8rem;
    }

    .selcet-treaty {
        line-height: 1;
        color: theme-color();
    }
}