blockquote,
body,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
li,
ol,
p,
pre,
td,
th,
ul {
    margin: 0;
    padding: 0
}

button,
img,
input,
object,
select,
textarea {
    border: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%
}

ol,
ul {
    list-style: none
}

address,
caption,
cite,
code,
dfn,
em,
strong,
var {
    font-size: normal;
    font-weight: 400
}

i {
    font-style: normal
}

b {
    font-weight: 400
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

img {
    max-width: 100%
}

a {
    text-decoration: none;
    color: inherit
}

p {
    max-height: 999999px
}