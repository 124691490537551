.faceCheckView {
  text-align: center;
  padding-top: .4rem;

  .h1 {
    font-size: .4rem;
    color: #333;
  }

  .h2 {
    font-size: .3rem;
  }

  .form-btn {
    width: 50%;
  }
  .form-btn.sub {
    margin-top: .2rem;
    color: theme-color();
    background-image: unset;
  }
}