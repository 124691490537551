@import "../../style/theme";
.bill-details-wrap {
    margin-top: .2rem;
    padding-top: 0.2rem;
    padding-bottom: 4rem;
  
    position: relative;
    z-index: 1;
  
    .bill-details {
        line-height: .65rem;
        font-size: .32rem;
        color: #333;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .bar {
            display: inline-block;
            width: 0.04rem;
            height: .4rem;
            background-color: theme-color();
            margin-right: .18rem;
            transform: translateY(.07rem);
        }
    }
  }
.bill {
    padding-top: .3rem;
    margin: 0 0.4rem;
    background-color: #f8f8f8;

    .head {
        min-height: 3rem;
        background: btn-bg-theme-color();
        border-radius: $border-radius;

        .house-info {
            min-height: 2.07rem;
            padding-top: .3rem;
            padding: 0 .42rem;
            box-sizing: border-box;
            position: relative;
            z-index: 1;

            p {
                line-height: .8rem;
                font-size: .32rem;
                color: #fff;
                display: flex;

                span {
                    margin-left: .22rem;
                }
            }

            .switching {
                position: absolute;
                right: 0;
                top: 0;
                width: 1rem;
                height: 1rem;
                border-radius: 0 $border-radius 0 0;
                line-height: .33rem;
                overflow: hidden;

                .switch-wrap {
                    position: absolute;
                    right: -.85rem;
                    top: 0;
                    color: theme-color();
                    transform: rotate(45deg);
                    width: 2rem;
                    height: 0.71rem;
                    background-image: linear-gradient(0deg, #E5E5E5 0%, #FFFFFF 14%);

                    i {
                        margin-left: 0.5rem;
                        font-size: .2rem;
                    }

                    span {
                        font-size: .24rem;
                    }
                }
            }
        }


        .contract {
            display: flex;
            justify-content: space-between;
            font-size: .28rem;
            margin: 0 .4rem;
            color: #fff;
            line-height: .92rem;
            border-top: 1px solid #ddd;
            box-sizing: border-box;

            .contract-right-sign {
                width: 1rem;
                height: .48rem;
                background-color: #fff;
                line-height: .48rem;
                text-align: center;
                color: theme-color();
                border-radius: $border-radius;
                margin-top: .22rem;
            }
        }
    }
}





.more {
    text-align: center;
    color: #888;
    font-size: .22rem;
    
}

.title-c8 {
    color: #888;
    font-size: .28rem;
    line-height: .55rem;
}

.overdue {
    color: #ff0000;
    font-size: .28rem;
}

.noOverdue {
    color: theme-color();
    font-size: .28rem;
}

.payment-date {
    color: #333;
    font-size: .28rem;
}